<template>
  <div class="papel_user">
    <div class="table_servis_list">
      <table class="table mt-4" v-loading="loadingData">
        <thead>
          <tr>
            <th class="wt5">П.П.</th>
            <th>Тип оплаты</th>
            <th class="wt7">Сумма</th>
            <th class="wt10">Валюта</th>
            <th class="wt10">Курс</th>
            <th class="wt7">Дата</th>
            <th class="wt10">Комментарий</th>
            <th class="wt10">Ползаватель</th>
            <th class="wt10">Настройка</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(ser, index) in list"
            :key="'order-create-in-header' + index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{ ser.payment_type ? ser.payment_type.name : "" }}
            </td>
            <td>
              {{ ser.amount | formatMoney }}
            </td>
            <td>
              {{ ser.currency }}
            </td>
            <td>
              {{ ser.rate }}
            </td>
            <td>
              {{ ser.created_at }}
            </td>
            <td>
              {{ ser.comment }}
            </td>
            <td>{{ ser.user.name }} {{ ser.user.surname }}</td>
            <td>
              <el-button
                type="success"
                @click="returnBack(ser)"
                icon="el-icon-refresh-right"
                size="small"
                plain
                >Возврат</el-button
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: ["selected"],
  data() {
    return {
      loadingData: false,
      activeNames: [""],
    };
  },
  computed: {
    ...mapGetters({
      list: "patientTransactions/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  watch: {
    selected: {
      handler: function (val, oldVal) {
        if (this.selected && !this.loadingData) {
          this.loadingData = true;
          this.editModel({
            hospital_patient_id: null,
            id: this.selected,
          })
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      },
    },
  },
  async mounted() {
    var vm = this;
    if (this.selected && !this.loadingData) {
      this.loadingData = true;
      this.editModel({
        hospital_patient_id: null,
        id: this.selected,
      })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    }
  },
  methods: {
    ...mapActions({
      editModel: "patientTransactions/indexitems",
      returnTo: "patientTransactions/returnBack",
    }),
    handleChange(val) {},
    returnBack(model) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.loadingData = true;
          this.returnTo(model.id)
            .then((res) => {
              this.fetchAgain();
              this.$emit("rollback");
              this.$emit("roll", model);
            })
            .catch((err) => {
              this.loadingData = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.operation_canceled"),
          });
        });
    },
    fetchAgain() {
      this.loadingData = true;
      this.editModel({
        hospital_patient_id: null,
        id: this.selected,
      })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
  },
};
</script>
<style lang="scss">
.papel_user .table_servis_list .wt10 {
  width: 12%;
}
.papel_user {
  .table_servis_list {
    padding: 0px;
  }
}
</style>