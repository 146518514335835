<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t('message.add_order_payment') }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submitAndQuit(true)">{{ $t('message.save_and_close') }}</el-button>
              <el-button  @click="quit(true)">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="modal_blocks_cashier p-3">
      <el-row :gutter="20" class="mt-3">
        <el-col :sm="12">
          <el-form ref="form" class="from_x">
          <el-autocomplete
          v-mask="'### ### ###'"
            v-model="state"
            :fetch-suggestions="querySearchAsync"
            :placeholder="$t('message.number_card')"
            suffix-icon="el-icon-search"
            @select="handleSelect"
            class="w_100s"
            clearable
          ></el-autocomplete>
          </el-form>
        </el-col>
        <el-col :sm="12">
        <div class="align-items-center align-self-center text-right pr-0 mb-2" v-loading="loadingData">
          <span class="align-items-center align-self-center pr-2">
            <el-tag>{{ $t('message.total_amount') }}: {{model.patientBalance.total_amount ? model.patientBalance.total_amount : 0 | formatMoney}} {{ $t('message.c_sum') }}</el-tag>
          </span>
          <span class="align-items-center align-self-center pr-2">
            <el-tag type="success">{{ $t('message.paid') }}: {{model.patientBalance.paid_up ? model.patientBalance.paid_up : 0 | formatMoney}} {{ $t('message.c_sum') }}</el-tag>
          </span>
          <span class="align-items-center align-self-center pr-2">
            <el-tag type="warning">{{ $t('message.not_paid') }}: {{model.patientBalance.not_paid ? model.patientBalance.not_paid : 0 | formatMoney}} {{ $t('message.c_sum') }}</el-tag>
          </span>
        </div>
        </el-col>
      </el-row>

      <el-tabs type="border-card" class="mt-4" v-loading="loadingData">
        <el-tab-pane :label="$t('message.add_transaction')" >
          <!-- <el-row>
            <el-button type="danger">Без расчета</el-button>
          </el-row> -->
          <el-form ref="form" size="small" :model="form">
            <el-row :gutter="20" class="mt-3">
              <el-col :span="5">
                <el-form-item :label="$t('message.payment_type')">
                  <el-select v-model="form.payment_type" style="width: 100%;" :placeholder="$t('message.payment_type')">
                    <el-option :label="$t('message.enumeration')" value="Перечисление"></el-option>
                    <el-option :label="$t('message.terminal')" value="Терминал"></el-option>
                    <el-option :label="$t('message.cach')" value="Наличные"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item :label="$t('message.amount')">
                  <el-input type="number" v-model="form.amount" :placeholder="$t('message.amount')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item :label="$t('message.date_0')">
                  <el-date-picker type="date" :placeholder="$t('message.date_0')" v-model="form.date_time" style="width: 100%;">
                  </el-date-picker>
                </el-form-item>
              </el-col>
               <el-col :span="5">
                <el-form-item :label="$t('message.comment')">
                  <el-input v-model="form.comment" :placeholder="$t('message.comment')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit()" style="width: 100%; margin-top: 19px; height: 32px;">{{ $t('message.add_transaction') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
         <el-tab-pane :label="$t('message.all_service')">
          <BalanceUslugaList :selected="model.id"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('message.history_transaction')">
          <BalanceList v-on:rollback="afterOpened" ref="payments" :selected="model.id"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import BalanceUslugaList from "./components/balanceUslugaList";
  import BalanceList from "./components/balanceList";
  import drawerChild from '@/utils/mixins/drawer-child';
  import { mapGetters, mapActions } from "vuex";
  export default {
    mixins:[drawerChild],
    name: "clientBalance",
    components: {
      BalanceUslugaList,
      BalanceList
    },
    data() {
      return {
        links: [],
        state: '',
        timeout:  null,
        loadingButton: false,
        loadingData: false,
        form: {},
        form2: {},
      };
    },

    computed:{
      ...mapGetters({
          patients: 'patientBalances/search_list',
          list: 'patientBalances/list',
          columns: "patientBalances/columns",
          pagination: "patientBalances/pagination",
          filter: "patientBalances/filter",
          sort: "patientBalances/sort",
          corpses: "patientBalances/list",
          model: 'patientBalances/model',
      }),
      actions: function() {
        return ['edit', 'delete'];
      }
    },
    methods: {
      ...mapActions({
          save: 'patientTransactions/store',
          updateList: 'patientBalances/index',
          updateSort: "patientBalances/updateSort",
          updateFilter: "patientBalances/updateFilter",
          updateColumn: "patientBalances/updateColumn",
          updatePatients: 'patientBalances/search',
          updatePagination: "patientBalances/updatePagination",
          editModel: 'patientBalances/show',
          empty: 'patientBalances/empty',
          delete: 'patientBalances/destroy',
          refreshData: 'patientBalances/refreshData',
          fetchModel: 'patientBalances/showModel',
      }),
      submit(){
        this.form.patient_history_id = this.model.id;
        this.save(this.form)
            .then(res => {
                this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                this.getModel(this.model)
                this.emptyModel()
                this.$refs.payments.fetchAgain()
            }).catch(err => {
                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
      },
      submitAndQuit(close = true){
        this.loadingButton = true;
        this.form.patient_history_id = this.model.id;
        this.save(this.form)
            .then(res => {
                this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                this.getModel(this.model);
                this.emptyModel();
                this.$refs.payments.fetchAgain();
                this.quit();
            })
            .catch(err => {
                this.loadingButton = false;
                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
      },
      getModelAfterSubmit(model) {
      this.focus = false
      this.loadingData = true;
      this.fetchModel({
          patient_hitory_id: model.link ? model.link: model.patient_history_id,
        })
        .then(res => {
          this.loadingData = false;
        }).catch(err => {
          this.loadingData = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
        });
      },
      getModel(model) {
      this.focus = false
      this.loadingData = true;
      this.fetchModel({
          patient_hitory_id: model.id,
        })
        .then(res => {
          this.loadingData = false;
        }).catch(err => {
          this.loadingData = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
        });
      },
      afterOpened(){
          this.$refs.serv.empty()
      },
      afterClosed(){
      },
      quit(close = true){
          this.form.payment_type = "";
          this.form.amount = "";
          this.form.date_time = "";
          this.form.comment = "";
          this.state = "";
          this.links = [];
          this.parent().listChanged()
          if (close == true) {
              this.close();
          }
      },
      emptyModel(){
         this.form.amount = "";
         this.form.date_time = "";
         this.form.comment = "";
      },
      querySearchAsync: _.debounce(function (queryString, cb) {
        var links = this.links;
        this.links = [];
        const query = { search: queryString };
        if (!this.loadingData) {
            this.loadingData = true;
            this.updatePatients(query).then(res => {
                this.patients.forEach(element => {
                this.form2.value = element.number;
                this.form2.link = element.id;
                this.links.push(this.form2);
                this.form2 = {};
                });
                
                var results = this.links;

                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  cb(results);
                }, 200);
                this.loadingData = false
            }).catch(err => {
                this.loadingData = false
            });
        }
      }, 500),
      handleSelect(item) {
        this.getModelAfterSubmit(item)
      }
    }
  };
</script>
<style lang="scss">
  .w_100s{
     width: 100%;
    input{
      width: 100%;
    }
  }
</style>